import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import { EmailIcon, FacebookIcon, TwitterIcon } from 'util/icons';
import { style } from './style';

class Share extends React.Component<models.store.IAppState> {
  public render(): React.ReactNode {
    const { copy, settings } = this.props.cmsData.text.share;
    const { contestantIndex } = this.props.globalProps;
    const contestant = this.props.globalProps.contestants[contestantIndex];
    const styles = style({
      shareStyles: this.props.stylesData.thankYou.share,
    });

    const tweet = copy.tweet
      .replace(/{{NAME}}/gi, contestant.name.trim())
      .replace(/{{HASHTAG}}/gi, contestant.hashtag.trim());

    return (
      <div className={css(styles.share)}>
        {(helpers.checkIfTrue(settings.display_twitter) ||
          helpers.checkIfTrue(settings.display_facebook)) && (
          <h3 className={css(styles.cta)}>{copy.cta}</h3>
        )}

        {helpers.checkIfTrue(settings.display_twitter) && (
          <button
            className={css(styles.social_button, styles.social_twitter)}
            onClick={() =>
              helpers.twitterShare(tweet, constants.GA_EVENTS.TWITTER_SHARE)
            }
            aria-label='Share on Twitter'
          >
            <TwitterIcon />
          </button>
        )}

        {helpers.checkIfTrue(settings.display_facebook) && (
          <button
            className={css(styles.social_button, styles.social_facebook)}
            onClick={() => this._facebookShare()}
            aria-label='Share on Facebook'
          >
            <FacebookIcon />
          </button>
        )}

        {helpers.checkIfTrue(settings.display_email) && (
          <a
            href={`mailto:?subject=${encodeURIComponent(
              copy.email_subject
            )}&body=${encodeURIComponent(copy.email_body)}`}
            className={css(styles.social_button, styles.social_email)}
            aria-label='Share on Email'
            target='_blank'
          >
            <EmailIcon />
          </a>
        )}
      </div>
    );
  }

  _facebookShare = () => {
    const { copy, settings } = this.props.cmsData.text.share;
    const shareURL = settings.facebook_share_url || `${window.location.href}`;
    const shareQuote = copy.facebook_share_quote || '';

    googleHelpers.trackGoogleEvent(
      constants.GA_CATEGORIES.BUTTON_CLICK,
      constants.GA_EVENTS.FACEBOOK_SHARE,
      ''
    );

    const url = encodeURIComponent(shareURL);
    const quote = encodeURIComponent(shareQuote);

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${quote}`,
      'popup',
      'width=600, height=400, scrollbars=no'
    );
  };
}

export default Connect(Share);
