import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { style } from './style';

class Button extends React.Component<models.button.IButtonData> {
  render() {
    const { copy } = this.props.buttonData;

    const styles = style({
      buttonStyles: this.props.buttonStyles ? this.props.buttonStyles : {},
      options: this.props.options || {},
    });

    return (
      <button
        className={css(styles.button)}
        onClick={this.props.onClick}
        aria-label={this.props.ariaLabel}
        aria-disabled={this.props.isDisabled}
      >
        {copy}
      </button>
    );
  }
}

export default Button;
