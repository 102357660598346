import BASE64 from 'crypto-js/enc-base64';
import HMACSHA256 from 'crypto-js/hmac-sha256';
import * as models from 'models/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import slugify from 'slugify';
import { isMobile } from 'react-device-detect';
import clone from 'lodash/clone';

export default class Util {
  /**
   * Deferred - create a deferred object to
   */
  public static Deferred(): models.promise.IDeferred {
    let res;
    let rej;

    const def = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    }) as models.promise.IDeferred;

    if (res && rej) {
      def.resolve = res;
      def.reject = rej;
    }

    return def;
  }
}

export const fixedEncodeUriComponent = (str: string) => {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, '%2A');
};

export const convertToQspString = (params: any) => {
  let result = '';
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      if (value) {
        const encodedValue = fixedEncodeUriComponent(String(value));
        const encodedKey = fixedEncodeUriComponent(String(key));
        result += `${encodedKey}=${encodedValue}&`;
      }
    }
  }
  return result.slice(0, -1);
};

export const createHash = (qsp: string, secret: string) => {
  const hash = HMACSHA256(qsp, secret);
  return BASE64.stringify(hash);
};

export const normalizeForUrl = (name: string) => {
  return slugify(name, {
    lower: true,
    strict: true,
  });
};

const toCamel = (s: any) => {
  return s.replace(/([-_][a-z])/gi, ($1: any) => {
    return $1.toUpperCase().replace('_', '');
  });
};

const isArray = function (a: any) {
  return Array.isArray(a);
};

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const normalizeStyles = function (styles: models.base.IGenericObject) {
  const o = clone(styles);

  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      if (k === 'background_image' && o[k]) {
        if (o[k] !== 'none' && o[k].indexOf('url(') === -1) {
          o[k] = `url(${o[k]})`;
        }
      }

      if (k === 'hover' && !isMobile) {
        const key = ':hover:not([aria-disabled="true"])';
        o[key] = o[k];
        k = key;
      }

      if (k === 'active') {
        const key = ':active';
        o[key] = o[k];
        k = key;
      }

      if (k === 'focus') {
        const key = ':focus';
        o[key] = o[k];
        k = key;
      }

      if (o[k] !== '') {
        n[toCamel(k)] = normalizeStyles(o[k]);
      }
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return normalizeStyles(i);
    });
  }

  return o;
};

export const checkIfTrue = (value: string) => {
  return ['true', '1', true, 1, 'yes'].indexOf(value) >= 0;
};

export const shuffleArr = (arr: []) => {
  const shuffledArr = [...arr];
  let currentIndex = shuffledArr.length;
  let temporaryValue;
  let randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = shuffledArr[currentIndex];
    shuffledArr[currentIndex] = shuffledArr[randomIndex];
    shuffledArr[randomIndex] = temporaryValue;
  }

  return shuffledArr;
};

export const sortArrByProp = (arr: [], field: string, dir: string) => {
  return [...arr].sort(
    (a: models.base.IGenericObject, b: models.base.IGenericObject) => {
      if (dir === 'ascending') {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }
        return 0;
      } else if (dir === 'descending') {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      }
      return 0;
    }
  );
};

export const twitterShare = (copy: string, tracking: string, link?: string) => {
  const linkOut = link ? `&url=${link}` : '';

  googleHelpers.trackGoogleEvent(
    constants.GA_CATEGORIES.BUTTON_CLICK,
    tracking,
    ''
  );
  const url = `${constants.TWITTER_INTENT_URL}${encodeURIComponent(
    copy
  )}${linkOut}`;
  window.open(url, '_blank', 'height=420,width=550');
};

export const insertFonts = (url: string) => {
  if (!url) {
    return;
  }
  const fontLink = document.createElement('link');
  document.head.appendChild(fontLink);
  fontLink.rel = 'stylesheet';
  fontLink.href = url;
};

/**
 * insertAt
 * Inserts an item into a list at a given index
 * @param list list to have item inserted into
 * @param item item to be inserted into list
 * @param i index to insert item at
 */
export const insertAt = (list: any[], item: any, i: number) => {
  const updatedList = [...list];

  if (!item) {
    return updatedList;
  }

  updatedList.splice(i, 0, item);

  return updatedList;
};

/**
 * Pluralizes a string if the count is not equal to 1. Only works for strings in which the plural form ends in 's'.
 * Preserves the case of the string.
 * This is a specific use case. This function is not intended to be a generalized pluralizer
 * @example
 * pluralize(10, vote) // votes
 * pluralize(1, VOTE) // VOTE
 * pluralize(2, Vote, true) // 2 Votes
 * @param count number of things
 * @param str string to pluralize if count is not 1
 * @param inclusive whether to prefix the str with the count
 * @returns pluralized form of string if it should be pluralized, preserving case.
 */
export const pluralizeS = (count: number, str: string, inclusive = false) => {
  const isUpperCase = str.substr(-1).toUpperCase() === str.substr(-1);
  const plural = isUpperCase ? `${str}S` : `${str}s`;

  if (count === 1) {
    return inclusive ? `${count} ${str}` : str;
  }

  return inclusive ? `${count} ${plural}` : `${plural}`;
};

export function getQSPs() {
  const qsps = Object.create(null);

  window.location.search
    .slice(1)
    .split('&')
    .forEach((item) => {
      const [key, value = ''] = item.split('=');

      qsps[key] = value;
    });

  return qsps;
}
