import * as models from 'models/index';
import { AppStateProvider } from 'store/index';
import * as cmsHelpers from 'util/cms-helpers';
import { normalizeStyles } from 'util/helpers';

export function handleGeoData(
  this: AppStateProvider,
  data: null | models.store.IRegionCheck
): void {
  const cmsProps: models.base.IGenericObject = { ...this.state.cmsProps };
  cmsProps.inRegion = !data || cmsHelpers.checkIfInRegion(data);
  const { globalProps } = this.state;
  globalProps.userData.country = data ? data.userCountry : '';
  globalProps.userData.state = data ? data.userRegion : '';

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      cmsProps,
      globalProps,
    };
  });
}

export function storeCmsData(
  this: AppStateProvider,
  cmsData: models.api.ICmsData
): void {
  this.setState((prev: models.store.IAppState) => {
    return {
      ...prev,
      cmsData,
    };
  });
}

export function storeStyles(
  this: AppStateProvider,
  stylesData: models.api.ICmsData
): void {
  const cmsProps: models.base.IGenericObject = { ...this.state.cmsProps };
  const normalizedStyles = normalizeStyles({ ...stylesData.text });
  cmsProps.isAppReady = true;

  this.setState((prev: models.store.IAppState) => {
    return {
      ...prev,
      stylesData: normalizedStyles,
      cmsProps,
    };
  });
}
