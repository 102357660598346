import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    terms_group: {
      margin: '0 auto 20px',
    },

    term: {
      display: 'flex',
      flexDirection: 'row',
      lineHeight: 1.5,
      paddingBottom: '10px',
      position: 'relative',
      width: '100%',
    },

    label: {
      cursor: 'pointer',
      flexGrow: 1,
      position: 'relative',
      paddingLeft: '30px',

      ':nth-child(1n) a': {
        margin: '0',
      },

      ':before': {
        backgroundColor: '#eee',
        background: styles.checkedTerms
          ? styles.termsStyles.checkbox.checked.background
          : styles.termsStyles.checkbox.unchecked.background,
        border: styles.checkedTerms
          ? styles.termsStyles.checkbox.checked.border
          : styles.termsStyles.checkbox.unchecked.border,
        content: '""',
        height: '15px',
        left: 0,
        position: 'absolute',
        top: '2px',
        width: '15px',
      },

      ':after': {
        border: 'none',
        borderBottom: '2px solid #000',
        borderLeft: '2px solid #000',
        borderColor: styles.termsStyles.checkbox.checked.color,
        content: '""',
        display: styles.checkedTerms ? 'block' : 'none',
        height: '4px',
        left: '3px',
        position: 'absolute',
        top: '6px',
        transform: 'rotate(-45deg)',
        width: '9px',
      },
    },

    labelError: {
      ':before': {
        ...styles.termsStyles.checkbox.error,
      },
    },

    link: {
      margin: 0,
      ...styles.termsStyles.optInLink,
    },

    copy: {
      ...styles.termsStyles.optIn,
    },

    error: {
      color: 'red',
      marginTop: '-5px',
      paddingLeft: '30px',
      display: !styles.checkedTerms ? 'block' : 'none',
      ...styles.termsStyles.error,
    },
  });
