import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    grid: {
      flexGrow: 1,
      padding: '20px 0',
      width: '100%',
      ...styles.globalStyles.container,

      [media.tablet]: {
        ...styles.globalStyles.container.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.container.desktop,
      },
    },

    textWrapper: {
      display: 'flex',
      paddingBottom: '10px',
      ...styles.gridStyles.headlineContainer,

      [media.tablet]: {
        paddingBottom: '0',
        ...styles.gridStyles.headlineContainer.tablet,
      },

      [media.desktop]: {
        ...styles.gridStyles.headlineContainer.desktop,
      },
    },

    headline: {
      paddingBottom: '0px',
      ...styles.globalStyles.headlines,
      ...styles.gridStyles.headline,

      [media.tablet]: {
        fontSize: '40px',
        lineHeight: '1.2',
        ...styles.globalStyles.headlines.tablet,
        ...styles.gridStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.gridStyles.headline.desktop,
      },
    },

    subheadlineWrapper: {
      display: 'flex',
      flexDirection: 'column',

      [media.tablet]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },

    description: {
      ...styles.gridStyles.description,

      [media.tablet]: {
        fontSize: '20px',

        ...styles.gridStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.gridStyles.description.desktop,
      },
    },

    separator: {
      display: 'none',
      ...styles.gridStyles.description,

      [media.tablet]: {
        display: 'block',
        fontSize: '20px',
        padding: '0 20px',

        ...styles.gridStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.gridStyles.description.desktop,
      },
    },

    votesRemaining: {
      flexGrow: 1,
      ...styles.gridStyles.description,

      [media.tablet]: {
        fontSize: '20px',

        ...styles.gridStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.gridStyles.description.desktop,
      },
    },

    additionalInfo: {
      fontSize: '14px',
      padding: '10px 0 0',
      ...styles.gridStyles.additionalInfo,

      [media.tablet]: {
        fontSize: '16px',
        ...styles.gridStyles.additionalInfo.tablet,
      },

      [media.desktop]: {
        ...styles.gridStyles.additionalInfo.desktop,
      },
    },

    panelWindow: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      width: 'auto',
      minWidth: '100%',
      margin: '0 -15px',
    },

    sortWrapper: {
      paddingTop: '10px',

      [media.tablet]: {
        paddingTop: '0',
      },
    },

    sortButtonInactive: {
      backgroundColor: 'transparent',
      display: 'inline-block',
      color: '#999',
      cursor: 'pointer',
      fontSize: '16px',
    },

    sortButtonActive: {
      backgroundColor: 'transparent',
      display: 'inline-block',
      pointerEvents: 'none',
      cursor: 'default',
      color: '#000',
      fontSize: '16px',
    },

    sortLabel: {
      color: '#999',
      fontSize: '16px',
    },
  });
