import { css } from 'aphrodite/no-important';
import Video from 'components/video';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import { style } from './style';

class Closed extends React.Component<models.store.IAppState> {
  componentDidMount() {
    googleHelpers.trackGooglePage(constants.GA_PAGES.CLOSED);
  }

  render() {
    const { copy, media } = this.props.cmsData.text.closed;
    const styles = style({
      closedStyles: this.props.stylesData.closed,
      globalStyles: this.props.stylesData.global,
    });

    return (
      <div className={css(styles.closed)}>
        <h1 className={css(styles.headline)} dangerouslySetInnerHTML={{ __html: copy.headline }} />
        <p className={css(styles.description)} dangerouslySetInnerHTML={{ __html: copy.description }} />

        {this.props.children}

        {media.image && !media.video && (
          <img src={media.image} alt={copy.alt_text} />
        )}

        {media.video && (
          <div className={css(styles.video_wrapper)}>
            <Video url={media.video} />
          </div>
        )}
      </div>
    );
  }
}

export default Connect(Closed);
