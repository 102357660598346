import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    ads: {
      textAlign: 'center',
    },

    adLink: {
      margin: '0',
      display: 'inline-block',
    },

    modal: {
      paddingBottom: '25px',
    },

    mobile_rectangle: {
      backgroundColor: 'transparent',
      width: '88px',
      ...styles.adUnitStyles.default,
      [media.tablet]: {
        display: 'none',
      },
    },

    leaderboard_wrapper: {
      display: 'none',

      [media.tablet]: {
        display: 'block',
      },
    },

    leaderboard_bottom_wrapper: {
      display: 'none',

      [media.tablet]: {
        display: 'block',
      },
    },

    mobile_leaderboard_wrapper: {
      ...styles.adUnitStyles.default,
      [media.tablet]: {
        display: 'none',
      },
    },

    mobile_leaderboard_bottom_wrapper: {
      ...styles.adUnitStyles.default,
      [media.tablet]: {
        display: 'none',
      },
    },

    rectangle_wrapper: {
      display: 'none',
      marginLeft: 'auto',
      [media.tablet]: {
        display: 'block',
      },
    },

    square_wrapper: {
      marginTop: '15px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    sponsored_by: {
      color: '#fff',
      display: 'none',
      marginBottom: '5px',
      ...styles.adUnitStyles.sponsoredBy,
      [media.tablet]: {
        display: 'block',
      },
    },
  });
