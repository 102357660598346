import { css } from 'aphrodite/no-important';
import Button from 'components/button';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as helpers from 'util/helpers';
import { style } from './style';

class Confirmation extends React.Component<models.store.IAppState> {
  public render(): React.ReactNode {
    const {
      contestantIndex,
      appSettings: { isMultiVote },
    } = this.props.globalProps;
    const contestant = this.props.globalProps.contestants[contestantIndex];
    const { copy, settings, buttons } = this.props.cmsData.text.thank_you;
    const { thankYou, global } = this.props.stylesData;
    const styles = style({
      globalStyles: global,
      confirmationStyles: thankYou,
    });
    const votes = this.props.voteProps.contestantVotes[contestant.id] || 0;
    const voteCopy = `${votes} ${votes === 1 ? 'VOTE' : 'VOTES'}`;

    const voteTweet = copy.twitter_vote.tweet
      .replace(/{{NAME}}/gi, contestant.name.trim())
      .replace(/{{HASHTAG}}/gi, contestant.hashtag.trim());

    return (
      <section className={css(styles.thanks)}>
        <div className={css(styles.media_container)}>
          <img src={contestant.image} alt={contestant.name} />
        </div>

        <div
          className={css(styles.information_container)}
          aria-live='assertive'
        >
          <h1 className={css(styles.headline)}> {copy.headline} </h1>

          {isMultiVote && <p className={css(styles.votes)}>{voteCopy}</p>}

          {copy.description_1 && (
            <p className={css(styles.description_1)}> {copy.description_1} </p>
          )}

          {copy.description_2 && (
            <p className={css(styles.description_2)}> {copy.description_2} </p>
          )}

          {helpers.checkIfTrue(settings.display_twitter_vote) && (
            <div className={css(styles.twitter_options)}>
              <h2 className={css(styles.twitter_headline)}>
                {copy.twitter_vote.headline}
              </h2>

              <p className={css(styles.twitter_description)}>
                {copy.twitter_vote.description}
              </p>

              <Button
                buttonData={buttons.twitter_vote}
                buttonStyles={thankYou.buttons.twitterVote}
                onClick={() =>
                  helpers.twitterShare(
                    voteTweet,
                    constants.GA_EVENTS.TWITTER_VOTE
                  )
                }
                options={{
                  pushDown: true,
                  globalStyles: this.props.stylesData.global.buttons,
                }}
              />
            </div>
          )}

          {helpers.checkIfTrue(settings.display_vote_again) && (
            <Button
              buttonData={buttons.vote_again}
              buttonStyles={thankYou.buttons.voteAgain}
              onClick={this.props.modalFn.closeModal}
              options={{
                pushDown: true,
                globalStyles: this.props.stylesData.global.buttons,
              }}
            />
          )}

          {(helpers.checkIfTrue(settings.display_share.display_facebook) ||
            helpers.checkIfTrue(settings.display_share.display_twitter)) &&
            this.props.children}
        </div>
      </section>
    );
  }
}

export default Connect(Confirmation);
