import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import * as helpers from 'util/helpers';
import { style } from './style';

class Terms extends React.Component<models.store.IAppState> {
  public componentWillMount(): void {
    this._storeTermNames();
  }

  public render(): React.ReactNode {
    const styles = style({
      termsStyles: this.props.stylesData.login.terms,
      checkedTerms: {},
      settings: {},
    });

    return (
      <div className={css(styles.terms_group)}>
        <ul>{this._getOptInsList().map(this._renderOptin)}</ul>
      </div>
    );
  }

  private _getOptInsList = () => {
    return Object.keys(this.props.cmsData.text.login.terms)
      .filter((category) => {
        return category.substring(0, 7) === 'opt_in_';
      })
      .sort();
  };

  private _handleCheckbox = (name: string, isRequired: string) => {
    const { termsProps } = this.props;
    termsProps[name].isChecked = !termsProps[name].isChecked;

    if (helpers.checkIfTrue(isRequired) && !termsProps[name].isChecked) {
      termsProps[name].hasError = true;
    } else {
      termsProps[name].hasError = false;
    }
    this.props.termsFn.updateTerms(termsProps);
  };

  private _renderOptin = (optIn: string, i: number) => {
    const { copy, settings } = this.props.cmsData.text.login.terms[optIn];
    const termsProps = this.props.termsProps[copy.name];
    const hasError =
      termsProps && termsProps.hasError ? termsProps.hasError : false;

    const styles = style({
      termsStyles: this.props.stylesData.login.terms,
      checkedTerms: termsProps ? termsProps.isChecked : settings.is_prechecked,
      settings,
    });

    const dangerLabel = (
      <label
        className={css(
          styles.label,
          styles.copy,
          hasError && styles.labelError
        )}
        dangerouslySetInnerHTML={{ __html: `${copy.copy}` }}
        htmlFor={copy.name}
      />
    );

    if (!copy.copy) {
      return;
    }

    return (
      <li key={i}>
        <div className={css(styles.term)}>
          <input
            defaultChecked={helpers.checkIfTrue(settings.prechecked)}
            id={copy.name}
            type='checkbox'
            onChange={() => this._handleCheckbox(copy.name, settings.required)}
          />

          {dangerLabel}
        </div>

        {hasError && (
          <p className={css(styles.error)} role='alert' aria-live='assertive'>
            {copy.error}
          </p>
        )}
      </li>
    );
  };

  private _storeTermNames = () => {
    const optInsList = this._getOptInsList().reduce((storage, optInName) => {
      const optIn = this.props.cmsData.text.login.terms[optInName];
      const termsProps = { ...this.props.termsProps };

      if (termsProps[optIn.copy.name]) {
        storage[optIn.copy.name] = termsProps[optIn.copy.name];
      } else {
        storage[optIn.copy.name] = {};
        storage[optIn.copy.name].isChecked = helpers.checkIfTrue(
          optIn.settings.prechecked
        );
        storage[optIn.copy.name].isRequired = helpers.checkIfTrue(
          optIn.settings.required
        );
      }

      return storage;
    }, Object.create(null));

    this.props.termsFn.updateTerms(optInsList);
  };
}

export default Connect(Terms);
