import * as ReactGA from 'react-ga';
import * as constants from 'util/constants';

let isGoogleInitialized = false;
const GA_LOCAL_STORAGE_KEY = 'ga:clientId';

export const initializeGoogleAnalytics = (gaId: string) => {
  if (gaId && !isGoogleInitialized) {
    let options;

    if (window.self !== window.top && window.localStorage) {
      options = {
        gaOptions: {
          storage: 'none',
          clientId: localStorage.getItem(GA_LOCAL_STORAGE_KEY) || undefined,
        },
      };

      ReactGA.initialize(gaId, options);
      ReactGA.ga(function (tracker: any) {
        localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
      });
    } else {
      ReactGA.initialize(gaId);
    }

    isGoogleInitialized = true;
  }
};

export const trackGoogleEvent = (
  category: string,
  action: string,
  label: string
) => {
  if (isGoogleInitialized) {
    ReactGA.event({ category, action, label });
  }
};

export const trackGooglePage = (page: string) => {
  if (isGoogleInitialized) {
    ReactGA.pageview(page);
  }
};

export const checkLinkClick = (evt: any) => {
  if (evt.target.tagName.toLowerCase() === 'a') {
    const category = constants.GA_CATEGORIES.LINK_CLICK;
    const action = evt.target.textContent + evt.target.href;
    const label = evt.target.textContent + evt.target.href;
    trackGoogleEvent(category, action, label);
  }
};

export const addLinkClickListener = () => {
  document.addEventListener('click', checkLinkClick, false);
};

export const removeLinkClickListener = () => {
  document.removeEventListener('click', checkLinkClick, false);
};
