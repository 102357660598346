import * as models from 'models/index';
import { AppStateProvider } from 'store/index';

export function updateRetryFb(
  this: AppStateProvider,
  shouldRetryFb: boolean
): void {
  const { loginProps } = this.state;
  loginProps.shouldRetryFb = shouldRetryFb;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      loginProps,
    };
  });
}
