import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    modal: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      display: 'block',
      overflow: 'auto',
      ...styles.modalStyles.overlay,

      [media.tablet]: {
        ...styles.modalStyles.overlay.tablet,
      },

      [media.desktop]: {
        ...styles.modalStyles.overlay.desktop,
      },
    },

    content: {
      position: 'relative',
      width: '100%',
      background: '#fff',
      maxWidth: '455px',
    },

    container: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',

      [media.tablet]: {
        alignItems: 'center',
        padding: '50px',
      },
    },

    close: {
      backgroundColor: 'transparent',
      padding: '0px',
      position: 'absolute',
      right: '10px',
      top: '10px',
      zIndex: 1,

      [media.tablet]: {
        right: '-30px',
        top: '0px',
      },

      [children('svg')]: {
        ...styles.modalStyles.closeButton,

        [media.tablet]: {
          ...styles.modalStyles.closeButton.tablet,
        },

        [media.desktop]: {
          ...styles.modalStyles.closeButton.desktop,
        },
      },

      ':hover svg': {
        fill: darkenColor(styles.modalStyles.closeButton.fill),

        [media.tablet]: {
          fill: styles.modalStyles.closeButton.tablet
            ? darkenColor(styles.modalStyles.closeButton.tablet.fill)
            : '',
        },

        [media.desktop]: {
          fill: styles.modalStyles.closeButton.tablet
            ? darkenColor(styles.modalStyles.closeButton.desktop.fill)
            : '',
        },
      },
    },
  });
