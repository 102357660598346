import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as helpers from 'util/helpers';
import { appMaxWidth, children, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    header: {
      backgroundColor: 'transparent',
      margin: '0 auto',
      maxWidth: helpers.checkIfTrue(styles.settings.displayBleed)
        ? 'none'
        : appMaxWidth,
      minHeight: '175px',
      position: 'relative',
      width: '100%',
      ...styles.headerStyles.general,
      backgroundPosition: '-35px 0',

      [media.mobile]: {
        backgroundPosition: styles.headerStyles.general.backgroundPosition,
      },

      [media.tablet]: {
        ...styles.headerStyles.general.tablet,
      },

      [media.desktop]: {
        ...styles.headerStyles.general.desktop,
      },
    },

    ad: {
      marginLeft: 'auto',
      marginTop: '15px',
      width: '88px',

      [media.tablet]: {
        width: '120px',
      },
    },

    container: {
      display: 'flex',
      margin: '0 auto 0 -25px',
      maxWidth: appMaxWidth,
      padding: 0,

      [media.mobile]: {
        ...styles.globalStyles.container,
        alignItems: 'center',
        margin: '0 auto',
        padding: 0,
      },

      [media.tablet]: {
        ...styles.globalStyles.container.tablet,
        padding: 0,
      },

      [media.desktop]: {
        ...styles.globalStyles.container.desktop,
        padding: 0,
      },
    },

    copy_wrapper: {
      padding: '5px',

      [media.phablet]: {
        maxWidth: 'none',
        padding: '20px 10px',
      },

      [media.tablet]: {
        padding: '25px 12px',
      },

      [media.desktop]: {
        padding: '35px 20px',
      },
    },

    headline: {
      ...styles.globalStyles.headlines,
      ...styles.headerStyles.headline,
      fontSize: '20px',
      paddingBottom: '0px',

      [children('span')]: {
        color: '#F01446',
        fontWeight: 700,
      },

      [media.tablet]: {
        fontSize: '28px',
        ...styles.globalStyles.headlines.tablet,
      },

      [media.desktop]: {
        fontSize: '36px',
        ...styles.globalStyles.headlines.desktop,
      },
    },

    description: {
      ...styles.headerStyles.description,
      fontSize: '14px',

      [media.tablet]: {
        ...styles.headerStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.headerStyles.description.desktop,
      },
    },

    logo: {
      ...styles.headerStyles.logo,
      maxWidth: 185,

      [children('img')]: {
        height: '100%',
        maxHeight: 'initial',
        maxWidth: 'initial',

        [media.tablet]: {
          height: 'auto',
          maxHeight: '100%',
          maxWidth: '100%',
        },
      },

      [media.tablet]: {
        ...styles.headerStyles.logo.tablet,
        maxWidth: 'none',
      },

      [media.desktop]: {
        ...styles.headerStyles.logo.desktop,
        minWidth: 350,
      },
    },
  });
