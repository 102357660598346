function setCookie(cname: string, cvalue: string, exdays: number | undefined) {
  let expires = '';

  if (exdays !== undefined) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    expires = 'expires=' + d.toUTCString();
  }

  let domain = window.location.hostname;
  domain = domain.replace(/^www\./, '');

  document.cookie =
    cname + '=' + cvalue + ';' + expires + ';path=/' + ';domain=' + domain;
}

function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function eraseCookie(name: string) {
  setCookie(name, '', -1);
}

export const cookieStorage = {
  getItem: (name: string) => {
    return getCookie(name);
  },

  removeItem: (name: string) => {
    return eraseCookie(name);
  },

  setItem: (
    name: string,
    value: string,
    expiration: number | undefined = undefined
  ) => {
    return setCookie(name, value, expiration);
  },
};
