import { IModalFunctions } from 'models/modal';
import * as models from 'models/index';
import { AppStateProvider } from 'store/index';
import { ACTION_TYPES } from 'util/constants';
import api from 'util/api';
import * as constants from 'util/constants';
import { useState } from 'react';
import * as googleHelpers from 'util/google-helpers';
import { IVoteData } from 'models/vote';

export function getContestantVotes(this: AppStateProvider): any {
  const payload = this.state.apiFn.generatePayload(ACTION_TYPES.GET);
  payload.action_type = ACTION_TYPES.GET;

  return api
    .postToVoteApi(payload, this.state.cmsData.settings.version_id)
    .then((response: any) => {
      this.state.voteFn.updateVotes(response.votestring);
    });
}

export function updateVotes(this: AppStateProvider, votestring: any): any {
  const { voteProps } = this.state;
  voteProps.contestantVotes = votestring ? { ...JSON.parse(votestring) } : {};
  voteProps.totalVotes = voteProps.contestantVotes.total || 0;
  delete voteProps.contestantVotes.total;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      voteProps,
    };
  });
}

export function submitVote(
  this: AppStateProvider,
  voteData: IVoteData
): Promise<IModalFunctions> {
  const { isMultiVote } = this.state.globalProps.appSettings;
  const { id, voteCount } = voteData;
  const { contestantVotes } = this.state.voteProps;
  const newVotes = { ...contestantVotes, [id]: voteCount };
  const total = [...Object.values(newVotes)].reduce(
    (acc: number, cur: number) => acc + cur
  );

  const payload = this.state.apiFn.generatePayload(
    constants.ACTION_TYPES.VOTE,
    {
      ...newVotes,
      ...(isMultiVote
        ? {
            total,
          }
        : {
            v: id,
          }),
    }
  );

  // TODO Move this out of store
  googleHelpers.trackGoogleEvent(
    constants.GA_CATEGORIES.BUTTON_CLICK,
    constants.GA_EVENTS.SUBMIT_VOTE,
    ''
  );

  return api
    .postToVoteApi(payload, this.state.cmsData.settings.version_id)
    .then((response: any) => {
      this.state.modalFn.closeModal();
      if (response.response_code === constants.RESPONSE_CODES.GENERAL_INVALID) {
        this.state.modalFn.openModal(constants.MODAL_TYPES.errorGeneric);
      } else if (response.response_code === constants.RESPONSE_CODES.VALID) {
        this.state.voteFn.updateVotes(response.votestring);
        this.state.modalFn.openModal(constants.MODAL_TYPES.confirmation);
      } else if (
        response.response_code === constants.RESPONSE_CODES.OVERLIMIT
      ) {
        this.state.modalFn.openModal(constants.MODAL_TYPES.errorOverlimit);
      } else if (
        response.response_code === constants.RESPONSE_CODES.OUT_OF_WINDOW
      ) {
        this.state.modalFn.openModal(constants.MODAL_TYPES.errorWindow);
      } else {
        this.state.modalFn.openModal(constants.MODAL_TYPES.errorGeneric);
      }
    })
    .catch(() => {
      this.state.modalFn.openModal(constants.MODAL_TYPES.errorGeneric);
    });
}

export function useVoteAttempt(userData: any) {
  const { isAuthorized, uuid } = userData;
  const [voteAttempt, setVoteAttempt]: [boolean, any] = useState(false);

  const isUserValid = isAuthorized && !!uuid;

  return { voteAttempt, setVoteAttempt, isUserValid };
}
