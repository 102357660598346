import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import { style } from './style';

class AdUnit extends React.Component<
  models.store.IAppState & models.ads.IAdUnitData
> {
  public render(): React.ReactNode {
    const { copy, unit } = this.props.cmsData.text.ads[`${this.props.size}`];
    const styles = style({
      adUnitStyles: this.props.cmsData.text.ads[`${this.props.size}`].styles,
      size: this.props.size,
    });

    return (
      <section
        className={css(styles.ads, styles[`${this.props.size}_wrapper`])}
      >
        {copy.sponsored_by && (
          <label className={css(styles.sponsored_by)}>
            {copy.sponsored_by}
          </label>
        )}

        {unit.link && (
          <a
            className={css(styles[this.props.size], styles.adLink)}
            href={unit.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={unit.image} alt={copy.alt_text} />
          </a>
        )}

        {!unit.link && (
          <div className={css(styles[this.props.size], styles.adLink)}>
            <img
              className={css(styles.adImage)}
              src={unit.image}
              alt={copy.alt_text}
            />
          </div>
        )}
      </section>
    );
  }
}

export default Connect(AdUnit);
