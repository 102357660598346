import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import { CloseIcon } from 'util/icons';
import { style } from './style';

class Modal extends React.Component<models.store.IAppState> {
  public myRef: any = React.createRef();
  public focusableEls: any = [];
  public firstFocusableEl: any = null;
  public lastFocusableEl: any = null;

  public componentWillMount(): void {
    document.body.classList.add('no-scroll');
  }

  public componentWillUnmount(): void {
    document.body.classList.remove('no-scroll');
    document.removeEventListener('keydown', this._handleKeyDown);
  }

  public componentDidMount(): void {
    this._trapFocus();
    document.addEventListener('keydown', this._handleKeyDown);
  }

  public componentDidUpdate(prevProps: models.store.IAppState): void {
    if (prevProps.modalProps.type !== this.props.modalProps.type) {
      this._trapFocus();
    }
  }

  render() {
    const styles = style({
      globalStyles: this.props.stylesData.global,
      modalStyles: this.props.stylesData.modal,
    });

    return (
      <dialog
        className={css(styles.modal)}
        onClick={this.props.modalFn.closeModal}
        ref={this.myRef}
        role='dialog'
      >
        <div className={css(styles.container)}>
          <div
            className={css(styles.content)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <button
              aria-label='close modal'
              onClick={this.props.modalFn.closeModal}
              className={css(styles.close)}
            >
              <CloseIcon />
            </button>

            {this.props.children}
          </div>
        </div>
      </dialog>
    );
  }

  _trapFocus = () => {
    const focusableEls = this.myRef.current.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    );
    this.focusableEls = Array.prototype.slice.call(focusableEls);

    this.firstFocusableEl = focusableEls[0];
    this.lastFocusableEl = focusableEls[focusableEls.length - 1];

    this.firstFocusableEl.focus();
  };

  _handleKeyDown = (e: any) => {
    switch (e.keyCode) {
      case constants.KEYS.ESCAPE:
        this.props.modalFn.closeModal();
        break;

      case constants.KEYS.TAB:
        if (this.focusableEls.length === 1) {
          break;
        }

        if (e.shiftKey) {
          if (document.activeElement === this.firstFocusableEl) {
            e.preventDefault();
            this.lastFocusableEl.focus();
          }
        } else {
          if (document.activeElement === this.lastFocusableEl) {
            e.preventDefault();
            this.firstFocusableEl.focus();
          }
        }
        break;

      default:
        break;
    }
  };
}

export default Connect(Modal);
