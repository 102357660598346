import * as models from 'models/index';
import * as React from 'react';
import * as api from 'store/api';
import * as auth from 'store/auth';
import * as cms from 'store/cms';
import * as global from 'store/global';
import * as grid from 'store/grid';
import * as loading from 'store/loading';
import * as login from 'store/login';
import * as modal from 'store/modal';
import * as terms from 'store/terms';
import * as vote from 'store/vote';
import * as constants from 'util/constants';

const { Consumer, Provider } = React.createContext(
  {} as models.store.IAppState
);

class AppStateProvider extends React.Component {
  public state: models.store.IAppState = {
    globalProps: {
      contestants: [],
      contestantIndex: 0,
      userData: {
        uuid: '',
        country: '',
        email: '',
        facebookId: '',
        isAuthorized: false,
        method: '',
        name: '',
        state: '',
      },
      appSettings: {
        isMultiVote: false,
      },
    },

    globalFn: {
      setTargetIndex: global.setTargetIndex.bind(this),
      setAppSettings: global.setAppSettings.bind(this),
    },

    apiFn: {
      generatePayload: api.generatePayload.bind(this),
    },

    authFn: {
      authorizeUser: auth.authorizeUser.bind(this),
      loginViaEmail: auth.loginViaEmail.bind(this),
      loginViaFacebook: auth.loginViaFacebook.bind(this),
      loginViaUUID: auth.loginViaUUID.bind(this),
      logoffEmail: auth.logoffEmail.bind(this),
      logoffFacebook: auth.logoffFacebook.bind(this),
    },

    cmsData: {},

    cmsFn: {
      handleGeoData: cms.handleGeoData.bind(this),
      storeCmsData: cms.storeCmsData.bind(this),
      storeStyles: cms.storeStyles.bind(this),
    },

    cmsProps: {
      inRegion: true,
      isAppReady: false,
    },

    loadingFn: {
      setDescription: loading.setDescription.bind(this),
      setTitle: loading.setTitle.bind(this),
    },

    loadingProps: {
      description: '',
      title: '',
      timeout: 0,
    },

    loginFn: {
      updateRetryFb: login.updateRetryFb.bind(this),
    },

    loginProps: {
      shouldRetryFb: false,
    },

    match: {},

    modalFn: {
      closeModal: modal.closeModal.bind(this),
      openModal: modal.openModal.bind(this),
    },

    modalProps: {
      type: '',
    },

    gridFn: {
      toggleSort: grid.toggleSort.bind(this),
      createContestantList: grid.createContestantList.bind(this),
    },

    gridProps: {
      sortingMethod: constants.ALPHABETICAL,
      sortingDirection: constants.ASCENDING,
    },

    termsFn: {
      updateTerms: terms.updateTerms.bind(this),
    },

    termsProps: {},

    stylesData: {},

    voteFn: {
      getContestantVotes: vote.getContestantVotes.bind(this),
      updateVotes: vote.updateVotes.bind(this),
      submitVote: vote.submitVote.bind(this),
    },

    voteProps: {
      contestantVotes: {},
      totalVotes: 0,
    },
  };

  public render(): React.ReactNode {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const Connect = (Component: typeof React.Component | ((props: any) => any)) => {
  return (props: models.base.IGenericObject) => {
    return <Consumer>{(data) => <Component {...data} {...props} />}</Consumer>;
  };
};

export { Connect, AppStateProvider };
