import { AppStateProvider } from 'store/index';
import { IVoteData } from 'models/vote';

export function generatePayload(
  this: AppStateProvider,
  actionType: string,
  voteData?: IVoteData
) {
  const action_type = actionType;
  const apiKey = this.state.cmsData.settings.apiKey;
  const country = this.state.globalProps.userData.country;
  const user_id = this.state.globalProps.userData.uuid;
  const method = this.state.globalProps.userData.method;
  const state = this.state.globalProps.userData.state;

  switch (actionType) {
    case 'get':
      return {
        action_type,
        apiKey,
        user_id,
        method,
        timestamp: new Date().getTime(),
      };
      break;
    case 'vote':
      return {
        action_type,
        apiKey,
        country,
        user_id,
        method,
        state,
        ...voteData,
        timestamp: new Date().getTime(),
      };
      break;
    default:
      // do nothing
      return null;
  }
}
