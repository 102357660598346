export const storageFactory = (storage: any) => {
  const inMemoryStorage = {};

  const isSupported = () => {
    try {
      const testKey = 'telescope_tv';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getItem = (name: string) => {
    if (isSupported()) {
      return storage.getItem(name);
    }
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  };

  const removeItem = (name: string) => {
    if (isSupported()) {
      storage.removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  };

  const setItem = (
    name: string,
    value: string,
    expiration: number | undefined = undefined
  ) => {
    if (isSupported()) {
      storage.setItem(name, value, expiration);
    } else {
      inMemoryStorage[name] = String(value);
    }
  };

  return {
    getItem,
    setItem,
    removeItem,
  };
};
