import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import * as googleHelpers from 'util/google-helpers';
import { style } from './style';

type Props = models.error.IErrorMessageData & models.store.IAppState;

class ErrorMessage extends React.Component<Props> {
  componentDidMount() {
    if (this.props.track) {
      googleHelpers.trackGooglePage(this.props.track);
    }
  }

  render() {
    const { copy } = this.props.data;
    const styles = style({
      errorStyles: this.props.styles,
      globalStyles: this.props.stylesData.global,
    });

    return (
      <div className={css(styles.error)} aria-live='assertive'>
        {this.props.data.image && this.props.data.image.image && (
          <img
            src={this.props.data.image.image}
            alt={this.props.data.image.alt}
            className={css(styles.image)}
          />
        )}

        <h1
          className={css(styles.headline)}
          dangerouslySetInnerHTML={{ __html: copy.headline }}
        />
        <p
          className={css(styles.description)}
          dangerouslySetInnerHTML={{ __html: copy.description }}
        />

        {this.props.data.link && this.props.data.link.url && (
          <a
            href={this.props.data.link.url}
            className={css(styles.link)}
            target='_blank'
            rel='noreferrer'
          >
            {this.props.data.link.copy}
          </a>
        )}
      </div>
    );
  }
}

export default Connect(ErrorMessage);
