import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    share: {
      paddingTop: '15px',
      textAlign: 'center',
    },

    cta: {
      ...styles.shareStyles.cta,

      [media.tablet]: {
        ...styles.shareStyles.cta.tablet,
      },

      [media.desktop]: {
        ...styles.shareStyles.cta.desktop,
      },
    },

    social_button: {
      display: 'inline-block',
      [children('svg')]: {
        height: '20px',
      },
      margin: '0 3px',
    },

    social_email: {
      margin: '0 3px',
    },

    social_facebook: {
      [children('svg')]: {
        fill: '#3B5999',
        ...styles.shareStyles.icons,
      },
    },

    social_twitter: {
      marginRight: '3px',
      [children('svg')]: {
        fill: '#1DA1F2',
        ...styles.shareStyles.icons,
      },
    },
  });
