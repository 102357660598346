import * as models from 'models/index';
import { AppStateProvider } from 'store/index';
import { AUTH_METHODS } from 'util/constants';

export function authorizeUser(this: AppStateProvider): void {
  const { userData } = this.state.globalProps;
  userData.isAuthorized = true;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      userData,
    };
  });
}

export function loginViaUUID(this: AppStateProvider, uuid: string): void {
  const { userData } = this.state.globalProps;
  userData.uuid = uuid;
  userData.method = AUTH_METHODS.UUID;

  this.setState(
    (prevState: models.store.IAppState) => {
      return {
        ...prevState,
        userData,
      };
    },
    () => {
      this.state.voteFn
        .getContestantVotes()
        .then(this.state.authFn.authorizeUser);
    }
  );
}

export function loginViaEmail(this: AppStateProvider, email: string): void {
  const { userData } = this.state.globalProps;
  userData.email = email;
  userData.method = AUTH_METHODS.EMAIL;

  this.setState(
    (prevState: models.store.IAppState) => {
      return {
        ...prevState,
        userData,
      };
    },
    () => {
      this.state.voteFn
        .getContestantVotes()
        .then(this.state.authFn.authorizeUser);
    }
  );
}

export function loginViaFacebook(
  this: AppStateProvider,
  payload: models.auth.IFacebookLoginPayload
): void {
  const userData = { ...this.state.globalProps.userData };
  userData.email = payload.email;
  userData.facebookId = payload.id;
  userData.method = AUTH_METHODS.FACEBOOK;
  userData.name = `${payload.first_name} ${payload.last_name}`;

  this.setState(
    (prevState: models.store.IAppState) => {
      return {
        ...prevState,
        globalProps: {
          ...prevState.globalProps,
          userData,
        },
      };
    },
    () => {
      this.state.voteFn
        .getContestantVotes()
        .then(this.state.authFn.authorizeUser);
    }
  );
}

export function logoffEmail(this: AppStateProvider): void {
  const { userData } = this.state.globalProps;
  userData.email = '';
  userData.facebookId = '';
  userData.isAuthorized = false;
  userData.method = '';
  userData.name = '';

  this.state.voteFn.updateVotes();
  this.state.termsFn.updateTerms({});
  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      userData,
    };
  });
}

export function logoffFacebook(this: AppStateProvider): void {
  const { userData } = this.state.globalProps;
  userData.email = '';
  userData.facebookId = '';
  userData.isAuthorized = false;
  userData.method = '';
  userData.name = '';

  this.state.voteFn.updateVotes();
  this.state.termsFn.updateTerms({});
  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      userData,
    };
  });
}
