import * as models from 'models/index';
import { AppStateProvider } from 'store/index';

export function setTargetIndex(this: AppStateProvider, index: number): void {
  const { globalProps } = this.state;
  globalProps.contestantIndex = index;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      globalProps,
    };
  });
}

export function setAppSettings(
  this: AppStateProvider,
  settings: models.global.IAppSettings
): void {
  const { globalProps } = this.state;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      globalProps: {
        ...globalProps,
        ...settings,
      },
    };
  });
}
