import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    button: {
      backgroundColor: 'black',
      color: '#fff',
      fontSize: '12px',
      minWidth: '95px',
      minHeight: '40px',
      marginBottom: styles.options.pushDown ? '10px' : '0',
      padding: '10px',
      ...styles.options.globalStyles,

      ':hover:not([aria-disabled="true"])': {
        background: darkenColor(styles.buttonStyles.background),
        color:
          styles.buttonStyles.background === 'transparent'
            ? darkenColor(styles.buttonStyles.color)
            : '',
      },

      ':focus:not([aria-disabled="true"])': {
        background: darkenColor(styles.buttonStyles.background),
        color:
          styles.buttonStyles.background === 'transparent'
            ? darkenColor(styles.buttonStyles.color)
            : '',
      },

      ...styles.buttonStyles,

      [media.tablet]: {
        ':hover:not([aria-disabled="true"])': {
          background: styles.buttonStyles.background.tablet
            ? darkenColor(styles.buttonStyles.background.tablet.fill)
            : '',
          color:
            styles.buttonStyles.background === 'transparent' &&
            styles.buttonStyles.background.tablet
              ? darkenColor(styles.buttonStyles.tablet.color)
              : '',
        },

        ':focus:not([aria-disabled="true"])': {
          background: styles.buttonStyles.background.tablet
            ? darkenColor(styles.buttonStyles.background.tablet.fill)
            : '',
          color:
            styles.buttonStyles.background === 'transparent' &&
            styles.buttonStyles.background.tablet
              ? darkenColor(styles.buttonStyles.tablet.color)
              : '',
        },

        ...styles.buttonStyles.tablet,
      },

      [media.desktop]: {
        ':hover:not([aria-disabled="true"])': {
          background: styles.buttonStyles.background.desktop
            ? darkenColor(styles.buttonStyles.background.desktop.fill)
            : '',
          color:
            styles.buttonStyles.background === 'transparent' &&
            styles.buttonStyles.background.desktop
              ? darkenColor(styles.buttonStyles.desktop.color)
              : '',
        },

        ':focus:not([aria-disabled="true"])': {
          background: styles.buttonStyles.background.desktop
            ? darkenColor(styles.buttonStyles.background.desktop.fill)
            : '',
          color:
            styles.buttonStyles.background === 'transparent' &&
            styles.buttonStyles.background.desktop
              ? darkenColor(styles.buttonStyles.desktop.color)
              : '',
        },

        ...styles.buttonStyles.desktop,
      },
    },
  });
