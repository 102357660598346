import { css } from 'aphrodite/no-important';
import AdUnit from 'components/ad-unit';
import Button from 'components/button';
import * as models from 'models/index';
import * as React from 'react';
import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Connect } from 'store/index';
import { useVoteAttempt } from 'store/vote';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import { checkIfTrue, insertAt } from 'util/helpers';
import { getCurrentBreakpoint } from 'util/style-helpers';
import { style } from './style';
import useWindowSize from 'hooks/use-window-size';

type PanelsProps = models.store.IAppState & models.global.IGenericProps;

function Panels(props: PanelsProps) {
  const { voteAttempt, setVoteAttempt, isUserValid } = useVoteAttempt(
    props.globalProps.userData
  );
  const { contestants } = props.globalProps;
  const buttonsData = props.cmsData.text.nominee.buttons;
  const buttonsStyles = props.stylesData.nominee.buttons;
  const { settings } = props.cmsData.text.nominee;
  const { columns } = props.cmsData.text.grid.settings;
  const isWindowOpen = checkIfTrue(props.cmsData.settings.window_status);
  const displayConfirmation = checkIfTrue(
    props.cmsData.text.grid.settings.display_confirmation
  );
  const adSettings = props.cmsData.text.ads.square.settings;
  const styles = style({
    columns: {
      mobile:
        parseInt(columns.mobile) > constants.COLUMNS.MOBILE
          ? 2
          : columns.mobile,
      tablet:
        parseInt(columns.tablet) > constants.COLUMNS.TABLET
          ? 4
          : columns.tablet,
      desktop:
        parseInt(columns.desktop) > constants.COLUMNS.DESKTOP
          ? 6
          : columns.desktop,
    },
    globalStyles: props.stylesData.global,
    panelStyles: props.stylesData.nominee,
  });

  const adUnit = checkIfTrue(adSettings.display) ? (
    <div
      className={css(styles.panel_wrapper, styles.ad_wrapper)}
      key='display_ad'
    >
      <AdUnit size={constants.AD_UNITS.SQUARE} />
    </div>
  ) : null;
  const adPosition = adSettings[`position_${getCurrentBreakpoint()}`] - 1;

  useWindowSize(); // set resize listener so that ad position gets re-checked if window is resized to different breakpoint
  useEffect(() => {
    if (isUserValid && props.modalProps.type === constants.MODAL_TYPES.login) {
      props.modalFn.closeModal();

      if (voteAttempt) {
        setVoteAttempt(false);
      }
    }

    if (voteAttempt && !isUserValid && !props.modalProps.type) {
      props.modalFn.openModal(constants.MODAL_TYPES.login);
      setVoteAttempt(false);
    }
  }, [voteAttempt, isUserValid, props.modalProps.type]);

  // create contestant panels
  const contestantPanels = contestants.map(
    (item: models.cms.ICmsOptions | any, i: number) => {
      const votes = props.voteProps.contestantVotes[item.id] || 0;
      const voteCopy = `${votes} ${votes === 1 ? 'VOTE' : 'VOTES'}`;
      const isEliminated = checkIfTrue(item.is_eliminated);
      const { vote, eliminated, closed } = buttonsData;
      const buttonData = isWindowOpen
        ? isEliminated
          ? eliminated
          : vote
        : closed;

      const buttonStyles = isWindowOpen
        ? buttonsStyles.vote
        : buttonsStyles.closed;

      // Item is a contestant
      return (
        <div className={css(styles.panel_wrapper)} key={item.id}>
          <div className={css(styles.panel)}>
            <LazyLoadImage alt={item.name} src={item.image} />

            <div className={css(styles.text_wrapper)}>
              <h2 className={css(styles.headline)}>{item.name}</h2>

              {checkIfTrue(settings.display_description_1) &&
                settings.display_description_1 && (
                  <p className={css(styles.description_1)}>
                    {item.description_1}
                  </p>
                )}

              {checkIfTrue(settings.display_description_2) &&
                settings.display_description_2 && (
                  <p className={css(styles.description_2)}>
                    {item.description_2}
                  </p>
                )}

              {checkIfTrue(settings.display_description_3) &&
                settings.display_description_3 && (
                  <p className={css(styles.description_3)}>
                    {item.description_3}
                  </p>
                )}

              <div className={css(styles.vote_wrapper)}>
                {checkIfTrue(settings.display_votes) && (
                  <p className={css(styles.votes)}>{voteCopy}</p>
                )}
                <Button
                  ariaLabel={`vote for ${item.name}`}
                  buttonData={buttonData}
                  buttonStyles={buttonStyles}
                  options={{
                    globalStyles: props.stylesData.global.buttons,
                  }}
                  onClick={() => _handleClick(item)}
                  isDisabled={_isButtonDisabled(item)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  );

  // Insert ad unit into list, if any
  const panels = insertAt(contestantPanels, adUnit, adPosition);

  return panels;

  function _handleClick(contestant: models.cms.ICmsOptions) {
    if (_isButtonDisabled(contestant)) {
      return;
    }

    const index = props.globalProps.contestants.findIndex(
      ({ id }: { id: string }) => id === contestant.id
    );

    googleHelpers.trackGoogleEvent(
      constants.GA_CATEGORIES.BUTTON_CLICK,
      constants.GA_EVENTS.SELECT_CONTESTANT,
      ''
    );
    props.globalFn.setTargetIndex(index);

    // If the voting is 'multi-vote', then we will always need to display the vote modal,
    // so override if false
    if (displayConfirmation || props.globalProps.appSettings.isMultiVote) {
      return props.modalFn.openModal(constants.MODAL_TYPES.vote);
    }

    return isUserValid
      ? props.voteFn.submitVote({ voteCount: 1, id: contestant.id })
      : setVoteAttempt({ voteAttempt: true });
  }

  function _isButtonDisabled(contestant: models.cms.ICmsOptions) {
    const isEliminated = checkIfTrue(contestant.is_eliminated);

    return isEliminated && !displayConfirmation;
  }
}

export default Connect(Panels);
