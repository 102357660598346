import * as models from 'models/index';
import { AppStateProvider } from 'store/index';

export function setDescription(
  this: AppStateProvider,
  description: string
): void {
  const { loadingProps } = this.state;
  loadingProps.description = description;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      loadingProps,
    };
  });
}

export function setTitle(this: AppStateProvider, title: string): void {
  const { loadingProps } = this.state;
  loadingProps.title = title;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      loadingProps,
    };
  });
}
