import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import ReactPlayer from 'react-player';
import { style } from './style';

function Video(props: models.video.IVideoProps) {
  const styles = style();
  const config = {
    url: props.url,
    controls: true,
    width: '100%',
    height: '100%',
  };
  return (
    <div className={css(styles.player_wrapper)}>
      <ReactPlayer
        className={css(styles.react_player)}
        url={config.url}
        controls={config.controls}
        width={config.width}
        height={config.height}
      />
    </div>
  );
}

export default Video;
