import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import { style } from './style';
import { SortingMethod } from 'models/grid';

class Grid extends React.Component<models.store.IAppState> {
  private styles: any;

  public componentDidMount(): void {
    const sortMethod = this.props.cmsData.text.grid.settings
      .default_sorting_method as SortingMethod;
    const sortDirection = this.props.cmsData.text.grid.settings
      .sorting_direction;
    googleHelpers.trackGooglePage(constants.GA_PAGES.GRID);

    this.props.gridFn.createContestantList();
    this.props.gridFn.toggleSort(sortMethod, sortDirection);
  }

  public componentDidUpdate(prevProps: models.store.IAppState): void {
    if (
      JSON.stringify(prevProps.cmsData.data) !==
        JSON.stringify(this.props.cmsData.data) ||
      prevProps.cmsData.text.grid.settings.display_eliminated !==
        this.props.cmsData.text.grid.settings.display_eliminated
    ) {
      const sortMethod = this.props.cmsData.text.grid.settings
        .default_sorting_method as SortingMethod;
      const sortDirection = this.props.cmsData.text.grid.settings
        .sorting_direction;
      this.props.gridFn.createContestantList();
      this.props.gridFn.toggleSort(sortMethod, sortDirection);
    }
  }

  public render(): React.ReactNode {
    const { copy } = this.props.cmsData.text.grid;

    this.styles = style({
      globalStyles: this.props.stylesData.global,
      gridStyles: this.props.stylesData.grid,
    });

    return (
      <section className={css(this.styles.grid)}>
        <div className={css(this.styles.textWrapper)}>
          <h1 className={css(this.styles.headline)}>{copy.headline}</h1>

          {this._renderSubheadline()}
        </div>
        <section className={css(this.styles.panelWindow)}>
          {this.props.children}
        </section>
        {copy.additional_info && (
          <p className={css(this.styles.additionalInfo)}>
            {' '}
            {copy.additional_info}{' '}
          </p>
        )}
      </section>
    );
  }

  _renderSortingMethods = () => {
    const method = this.props.gridProps.sortingMethod;

    const randomCls =
      method === constants.RANDOMIZED
        ? this.styles.sortButtonActive
        : this.styles.sortButtonInactive;
    const alphabeticalCls =
      method === constants.ALPHABETICAL
        ? this.styles.sortButtonActive
        : this.styles.sortButtonInactive;

    return (
      <div className={css(this.styles.sortWrapper)}>
        <span className={css(this.styles.sortLabel)}>Sort By: </span>
        <button
          aria-label='Random sort'
          aria-pressed={method === constants.RANDOMIZED}
          onClick={() => {
            this.props.gridFn.toggleSort(constants.RANDOMIZED);
          }}
          className={css(randomCls)}
        >
          Random
        </button>
        <span aria-hidden='true'> | </span>
        <button
          aria-label='Alphabetical sort'
          aria-pressed={method === constants.ALPHABETICAL}
          onClick={() => {
            this.props.gridFn.toggleSort(constants.ALPHABETICAL);
          }}
          className={css(alphabeticalCls)}
        >
          Alphabetical
        </button>
      </div>
    );
  };

  _renderSubheadline = () => {
    const { copy, settings } = this.props.cmsData.text.grid;
    const maxVotesTotal = parseInt(
      this.props.cmsData.text.vote_settings.max_votes_total,
      10
    );
    const votesRemaining = maxVotesTotal - this.props.voteProps.totalVotes;
    const remainingVotesCopy = copy.remaining_votes.replace(
      /{{(VOTE)}}/i,
      (...args: any) => helpers.pluralizeS(votesRemaining, args[1], true)
    );

    return (
      <div className={css(this.styles.subheadlineWrapper)}>
        <p className={css(this.styles.description)}>{copy.description}</p>
        {copy.remaining_votes && (
          <>
            <span className={css(this.styles.separator)}>|</span>
            <p className={css(this.styles.votesRemaining)}>
              {remainingVotesCopy}
            </p>
          </>
        )}
        {helpers.checkIfTrue(settings.display_sorting_methods) &&
          this._renderSortingMethods()}
      </div>
    );
  };
}

export default Connect(Grid);
