import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    player_wrapper: {
      position: 'relative',
      paddingTop: '56.25%',
    },
    react_player: {
      position: 'absolute',
      top: '0',
      left: '0',
    },
  });
