import * as models from 'models/index';
import { AppStateProvider } from 'store/index';

export function updateTerms(this: AppStateProvider, terms: any): void {
  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      termsProps: { ...terms },
    };
  });
}
